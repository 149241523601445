*{
  box-sizing: border-box;
}

.login-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #F2F4FA;
}

.login-page-login-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 88px;
  width: 353px;
  img {
    height: 52px;
    margin-bottom: 36px;
  }

  .login-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    width: 100%;
    margin-bottom: 20px;

    .label {
      font-weight: 500;
      font-size: 15px;
    }

    input {
      height: 49px;
      margin-top: 9px;
      width: calc(100%);
      border: solid 1px #DADDE5;
      padding: 0px;
      background-color: #FAFAFC;
      border-radius: 8px;
      font-weight: 500;
      font-size: 17px;
      padding: 0px 16px;
    }

    input:focus {
      border: solid 1px #20363B;
    }
  }
}

.login-page-img-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  img {
    height: 40vh;
  }
}

.login-btn {
  margin-top: 16px;
  font-weight: bold;
  font-size: 17px;
  cursor: pointer;
  border-radius: 8px;
  width: 353px;
  height: 53px;
  background-color: #20363B;
  color: #72FBA0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidenav-container {
  position: fixed;
  left: 24px;
  top: 24px;
  background-color: white;
  width: 203px;
  height: calc(100vh - 48px);
  border-radius: 16px;
  padding: 16px 20px;

  border: solid 1px rgba(228, 230, 237, 0.3);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .logout {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 12px 16px 12px 12px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.sidenav-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;

  img {
    cursor: pointer;
    height: 26px;
    user-select: none;
  }
}

.sidenav-items-container {
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .link {
    cursor: pointer;
    border-radius: 12px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size: 15;
    color: black;
    img {
      margin-left: 12px;
      margin-right: 8px;
      width: 24px;
      height: 24px;
    }
  }

  .link.active {
    background-color: #F2F4FA;
  }

  .link:hover {
    background-color: #F2F4FA;
    transition: 0.3s;
  }
}

.content-section {
  width: calc(100vw - 251px - 24px);
  margin: 24px 0 24px 251px;

  padding: 0;
}

.empty-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 64px;
  font-weight: bold;
  opacity: 0.8;
}

.tasklists-container {
  width: 100%;
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: #F2F4FA;
  border-radius: 12px;

  padding: 24px;
}

.table-searchbar-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  input {
    height: 44px;
    width: 353px;
    border: solid 1px #DADDE5;
    padding: 0px;
    background-color: #FAFAFC;
    border-radius: 8px;
    font-weight: 500;
    font-size: 15px;
    padding: 0px 16px;
  }

  .search-btn {
    cursor: pointer;
    border-radius: 8px;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center !important;
    gap: 4px;
    background-color: #20363B;
    color: #72FBA0;
    height: 44px;
    width: 124px;
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
    img {
      width: 28px;
      height: 28px;
    }
  }
}

.table-tabbar-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  .step-wrapper {
    position: relative;

    .circle {
      z-index: 2;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 8px;
      height: 8px;
      background-color: #FF243E;
      border-radius: 4px;
    }
  }

  .center {
    display: flex !important;
    justify-content: center;
    align-items: center !important;
  }

  .step {
    z-index: 1;
    cursor: pointer;
    padding: 0px 12px;
    height: 37px;
    background-color: white;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 500;
    color: #3B3D47;
  }

  .active {
    background-color: #20363B;
    color: white;
  }
}

.tasklists-direction-wrapper {
  padding: 16px;
  border: solid 1px #E4E6ED;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 500;
  color: #3B3D47;

  img {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }
}

.table-col {
  display: flex;
  flex-direction: column;
}

.table-header {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  background-color: #E9ECF5;
  color: #5C5F6B;
  font-size: 15px;
  font-weight: 500;
  padding-right: 6px;
  div {
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.table-content {
  overflow-y: scroll;
  max-height: calc(100vh - 48px - 48px - 234px);

  .empty-wrapper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 48px - 48px - 234px);
    justify-content: center;
    align-items: center;

    div {
      margin-top: 12px;
      font-size: 17px;
      font-weight: 500;
      color: #3B3D47;
    }
  }

  .empty {
    width: 96px;
    height: 96px;
  }

  .table-item {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    border-bottom: solid 1px #E4E6ED;

    div {
      height: 53px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #1C1E24;
    }
  }

  .table-item:hover {
    background-color: #E8ECF5;
  }
}

.consultant-policy-table {
  .empty-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    /* height: calc(100vh - 20px - 234px); */
    justify-content: center;
    align-items: center;

    div {
      margin-top: 12px;
      font-size: 17px;
      font-weight: 500;
      color: #3B3D47;
    }
  }
  .empty {
    width: 96px;
    height: 96px;
  }
}

.table-content-consultant {
  overflow-y: scroll;
  max-height: calc(100vh - 20px - 234px);

  .empty-wrapper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 20px - 234px);
    justify-content: center;
    align-items: center;

    div {
      margin-top: 12px;
      font-size: 17px;
      font-weight: 500;
      color: #3B3D47;
    }
  }

  .empty {
    width: 96px;
    height: 96px;
  }

  .table-item {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    border-bottom: solid 1px #E4E6ED;

    div {
      height: 53px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      color: #1C1E24;
    }
  }

  .table-item:hover {
    background-color: #E8ECF5;
  }
}

.table-content::-webkit-scrollbar, .table-content-consultant::-webkit-scrollbar{
  width: 6px;
}

.table-content::-webkit-scrollbar-thumb, .table-content-consultant::-webkit-scrollbar-thumb{
  background-color: #D5D9E5;
  border-radius: 4px;
}

.task-base-col1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex: 172;

  img{
    width: 23px;
    height: 15px;
    object-fit: cover;
  }
}

.task-base-col2 {
  flex: 462;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  
  img{
    height: 16px;
  }
}

.task-base-col3 {
  display: flex;
  flex: 124
}

.task-base-col4 {
  display: flex;
  flex: 136
}

.task-base-col5 {
  display: flex;
  flex: 145
}

.task-base-col6 {
  display: flex;
  flex: 116
}

.task-base-col7 {
  display: flex;
  flex: 157
}

.task-base-col8 {
  display: flex;
  flex: 285
}

.consultant-base{
  font-size: 15px;
  font-weight: 400;
  color: #1C1E24;
  line-height: 21px;
}

.consultant-base.img{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  img {
    width: 24px;
    object-fit: cover;
  }
}

.consultant-base.col1{
  display: flex;
  flex: 2
}

.consultant-base.col2{
  display: flex;
  flex: 4
}

.consultant-base.col3{
  display: flex;
  flex: 2
}

.consultant-base.col4{
  display: flex;
  flex: 1
}

.consultant-base.col5{
  display: flex;
  flex: 1
}

.consultant-base.col6{
  display: flex;
  flex: 2
}

.consultant-base.col7{
  display: flex;
  flex: 2
}

.consultant-base.col8{
  display: flex;
  flex: 2
}

/* task detail */

.taskdetail-container{
  display: flex;
  flex-direction: column;
  gap: 12px;

  width: 100%;
}

.task-action-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  border-radius: 16px;
  background-color: #20363B;

  padding: 20px 32px;
}

.task-action-text-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  text-align: center;
  white-space: pre-line;
}

.task-action-title{
  font-size: 17px;
  font-weight: 600;
  color: #ffffff;
  line-height: 25px;
}

.task-action-text{
  font-size: 15px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  line-height: 21px;
}

.task-action-btn{
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 14px 20px;
  background-color: #30EA8D;
  border-radius: 8px;

  font-size: 17px;
  font-weight: 700;
  line-height: 25px;
  color: #20363B;

  transition: 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover{
    opacity: .8;
  }
}

.taskdetail-head-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  width: 100%;
  padding: 24px;
  border-radius: 16px;
  background-color: #F2F4FA;
}

.taskdetail-head-title-wrap{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  img {
    width: 35px;
    height: 22px;
    object-fit: cover;
  }

  font-size: 24px;
  font-weight: 700;
  color: #1C1E24;
  line-height: 32px;
}

.taskdetail-table{
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: 4px;
  border-radius: 12px;
  background-color: #fff;
  width: 100%;
}

.taskdetail-table-head-wrap{
  display: flex;
  flex-direction: row;
  align-items: stretch;

  background-color: #F2F4FA;
  border-radius: 8px;
}

.taskdetail-table-head{
  display: flex;
  align-items: center;
  justify-content: center;

  height: 45px;

  font-size: 15px;
  font-weight: 500;
  color: #5C5F6B;
  line-height: 21px;
}

.taskdetail-table-body-wrap{
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.taskdetail-table-body{
  display: flex;
  align-items: center;
  justify-content: center;

  height: 53px;

  font-size: 15px;
  font-weight: 400;
  color: #1C1E24;
  line-height: 21px;
}

.taskdetail-table-body.th-product{
  flex-direction: row;
  gap: 8px;

  img{
    width: 20px;
    height: 20px;
  }
}

.taskdetail-table-head.th-product, .taskdetail-table-body.th-product{
  flex: 2.5;
}

.taskdetail-table-head.th-duration, .taskdetail-table-body.th-duration{
  flex: 1;
}

.taskdetail-table-head.th-premium, .taskdetail-table-body.th-premium{
  flex: 1;
}

.taskdetail-table-head.th-customer, .taskdetail-table-body.th-customer{
  flex: 1;
}

.taskdetail-table-head.th-consultant, .taskdetail-table-body.th-consultant{
  flex: 1;
}

.taskdetail-table-head.th-date, .taskdetail-table-body.th-date{
  flex: 1;
}

.taskdetail-table-head.th-status, .taskdetail-table-body.th-status{
  flex: 2;
}

.taskdetail-body-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;

  padding: 24px;
  border-radius: 16px;
  background-color: #F2F4FA;
}

.tab-menu-wrap{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 8px;
}

.tab-menu{
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  border-radius: 8px;
  height: 37px;
  padding: 0 12px;

  font-size: 15px;
  font-weight: 500;
  color: #3B3D47;
  line-height: 21px;

  transition: .25s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover{
    opacity: .7;
  }
}

.tab-menu.active{
  background-color: #20363B;
  color: #fff;
}

.detail-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  width: 100%;
}

.detail-info-title{
  font-size: 17px;
  font-weight: 700;
  color: #3B3D47;
  line-height: 25px;
}

.detail-info-tables-row{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;

  width: 100%;
}

.detail-info-table{
  display: flex;
  flex-direction: column;
  align-items: stretch;

  border-radius: 12px;
  border: 1px solid #DADDE5;
  flex-grow: 1;
  flex-shrink: 0;
}

.detail-info-table-row{
  display: flex;
  flex-direction: row;
  align-items: stretch;

  min-height: 38px;
  border-bottom: 1px solid #DADDE5;
}

.detail-info-table-row.last-item{
  border-bottom: none;
}

.detail-info-table-head{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 200px;
  padding: 0 16px;
  border-right: 1px solid #DADDE5;

  font-size: 13px;
  font-weight: 500;
  color: #5C5F6B;
  line-height: 19px;
}

.detail-info-table-body{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  flex: 1;
  padding: 8px 16px;
  min-height: 37px;
  max-width: 320px;

  font-size: 15px;
  font-weight: 500;
  color: #000000;
  line-height: 21px;
}

.back-btn{
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  gap: 4px;

  background-color: #F2F4FA;
  border-radius: 12px;

  width: fit-content;
  padding: 0 16px 0 8px;
  height: 44px;

  transition: .25s cubic-bezier(0.075, 0.82, 0.165, 1);

  font-size: 17px;
  font-weight: 700;
  color: #000;
  line-height: 25px;

  &:hover{
    opacity: .7;
  }

  img {
    width: 28px;
    height: 28px;
  }
}

/* upload modal */

.modal-overlay{
  z-index: 20;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, .5);
}

.upload-modal-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  padding: 36px;
  border-radius: 8px;
  background-color: #fff;
  width: 550px;
}

.upload-modal-body{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100% ;

}

.upload-modal-body-text-wrap{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
}

.upload-modal-body-title{
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 28px;
}

.upload-modal-body-text{
  font-size: 15px;
  font-weight: 400;
  color: #3B3D47;
  line-height: 21px;
}

.bold{
  font-weight: 700;
}

.underline{
  text-decoration: underline;
}

.upload-input-wrap{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  width: 100%;

  border: 1px solid #E4E6ED;
  border-radius: 8px;

  padding: 12px 16px 16px 16px;
}

.upload-input-text-wrap{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  width: 100%;
}

.upload-input-label{
  font-size: 15px;
  font-weight: 500;
  color: #000;
  line-height: 21px;
}

.upload-input-info{
  font-size: 13px;
  font-weight: 400;
  color: #2B2D36;
  line-height: 19px;
}

.upload-input-file{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  padding: 0 12px;
  height: 44px;
  width: 100%;
  
  border: 1px solid #EBECF2;
  border-radius: 8px;

  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }
}

.upload-input-file-name{
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  color: #3B3D47;
  display: flex;
  width: 380px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.upload-input-btn{
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  border-radius: 8px;
  background-color: #F2F4FA;

  width: 100%;
  height: 46px;

  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }

  transition: .25s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover{
    opacity: .7;
  }
}

.upload-input-btn-name{
  font-size: 15px;
  font-weight: 500;
  color: #111;
  line-height: 21px;
}

.upload-modal-foot{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  width: 100%;
}

.upload-btn-cancel{
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  height: 53px;
  background-color: #F2F4FA;
  border-radius: 8px;

  color: #000;
  font-size: 17px;
  font-weight: 700;
  line-height: 25px;

  transition: .25s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover{
    opacity: .7;
  }
}

.upload-btn-save{
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  height: 53px;
  background-color: #20363B;
  border-radius: 8px;

  color: #72FBA0;
  font-size: 17px;
  font-weight: 700;
  line-height: 25px;

  transition: .25s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover{
    opacity: .9;
  }
}

.upload-input-valid-wrap{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  width: 100%;
}

.upload-input-valid{
  font-size: 13px;
  font-weight: 400;
  color: #FF243E;
  line-height: 19px;
}

.task-action-state-table{
  display: flex;
  flex-direction: row;
  align-items: stretch;

  height: 45px;
  border: .5px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;

  margin-top: 16px;
}

.task-action-state-table-head{
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 16px;
  border-right: .5px solid rgba(255, 255, 255, 0.2);

  color: white;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
}

.task-action-state-table-body{
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 16px;

  color: white;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
}

.radio-outline{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  width: 16px;
  height: 16px;
  border: 1px solid #DADDE5;
  border-radius: 8px;

  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.radio-outline.active{
  border: 1px solid #30EA8D;

  .radio-inner{
    opacity: 1;
  }
}

.radio-inner{
  opacity: 0;

  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #30EA8D;

  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/* state modal */
.state-modal-container{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;

  padding: 36px;
  border-radius: 8px;
  background-color: #fff;
  width: 493px;
}

.state-modal-body{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
}

.state-modal-body-title{
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 28px;
}

.state-modal-select-wrap{
  display: flex;
  flex-direction: column;
  align-items: stretch;

  border-radius: 8px;
  border: 0.5px solid #DADDE5;
}

.state-modal-select-item{
  user-select: none;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  border-bottom: 0.5px solid #DADDE5;
  height: 48px;
  padding: 0 16px;

  transition: .1s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover{
    background-color: #F7F9FC;
  }
}

.state-modal-select-item.active{
  border-bottom: none;
}

.state-modal-select-item.last{
  border-bottom: none;
}

.state-modal-select-item-wrap{
  font-size: 15px;
  font-weight: 500;
  color: #3B3D47;
  line-height: 21px;
}

.state-modal-select-item-input-wrap{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0;
}

.state-modal-select-item-input-wrap.active{
  border-bottom: .5px solid #DADDE5;
  padding-bottom: 12px;
}

.state-modal-select-item-input-wrap.active.last{
  border-bottom: none;
}

.text-input{
  outline: none;
  height: 49px;
  border: 1px solid #DADDE5;
  background-color: #FAFAFC;
  border-radius: 8px;
  padding: 0 16px;
  width: calc(100% - 56px);

  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  color: #000;

  ::placeholder{
    color: #7F828F;
  }

  &:focus{
    border: 1px solid #000;
  }
}

.text-input.state-modal-input{
  margin-left: 40px;
}

.detail-info-policy-wrap{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;

  width: 100%;
}

.detail-info-policy{
  display: flex;
  flex-direction: row;
  align-items: stretch;

  border: 1px solid #DADDE5;
  border-radius: 12px;

  width: 100%;
}

.detail-info-policy-label{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  padding: 0 16px;
  height: 66px;

  font-size: 15px;
  font-weight: 500;
  color: #3B3D47;
  line-height: 21px;

  border-right: 1px solid #DADDE5;
}

.detail-info-policy-text{
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 16px;
  height: 66px;
  width: 100%;

  font-size: 24px;
  font-weight: 700;
  color: #3B3D47;
  line-height: 32px;
}

.detail-info-policy-row{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 12px;

  width: 100%;
}

.detail-info-policy-progress-row{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1 1 0;
  border: 1px solid #DADDE5;
  border-radius: 12px;
}

.detail-info-policy-progress-wrap{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 5px;

  flex: 1 1 0;
  padding: 0 20px;
  height: 66px;
}

.detail-info-policy-progress-wrap.first{
  border-right: 1px solid #DADDE5;
}

.detail-info-policy-progress-label-wrap{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.detail-info-policy-data-row{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1 1 0;
  border: 1px solid #DADDE5;
  border-radius: 12px;
}

.detail-info-policy-progress-bar-bg{
  display: flex;
  flex-direction: row;
  align-items: stretch;

  background-color: rgba(48,  234, 141, .3);
  border-radius: 2px;
}

.detail-info-policy-progress-bar{
  background-color: rgb(48,  234, 141);
  border-radius: 2px;
  height: 4px;
}

.detail-info-policy-progress-label{
  font-size: 13px;
  font-weight: 700;
  color: #3B3D47;
  line-height: 19px;
}

.detail-info-policy-progress-data{
  font-size: 13px;
  font-weight: 400;
  color: #3B3D47;
  line-height: 19px;
}

.detail-info-policy-data-row{
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.detail-info-policy-data-wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 0;
  gap: 8px;

  height: 66px;
  border-right: 1px solid #DADDE5;;
}

.detail-info-policy-data-wrap.last{
  border-right: none;
}

.detail-info-policy-data-label{
  font-size: 15px;
  font-weight: 500;
  color: #3B3D47;
  line-height: 21px;
}

.detail-info-policy-data{
  font-size: 17px;
  font-weight: 700;
  color: #3B3D47;
  line-height: 25px;
}

.detail-info-table-body-download{
  cursor: pointer;

  width: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  padding: 0 16px;

  img {
    width: 24px;
    height: 24px;
  }

  font-size: 15px;
  font-weight: 500;
  color: #000;
  line-height: 21px;

  &:hover{
    text-decoration: underline;
  }
}

.detail-info-table-col-half{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 0;

  border-radius: 12px;
  border: none;
}

.detail-info-table-col-half.border{
  border: 1px solid #DADDE5;
}

.detail-info-half-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 24px;
}

.detail-info-table-history{
  display: flex;
  flex-direction: column;
  gap: 4px;

  border-bottom: 1px solid #DADDE5;
  padding: 16px 24px;
}

.detail-info-table-history.last{
  border: none;
}

.detail-info-table-history-text{
  font-size: 17px;
  font-weight: 500;
  color: #3B3D47;
  line-height: 25px;
}

.detail-info-table-history-date{
  font-size: 13px;
  font-weight: 500;
  color: #7F828F;
  line-height: 19px;
}

.detail-info-table-body-between{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  flex: 1 1 0;
  padding: 0 4px 0 16px;

  font-size: 15px;
  font-weight: 500;
  color: #000;
  line-height: 21px;
}

.detail-info-table-btn{
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;

  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }

  font-size: 15px;
  font-weight: 500;
  color: #000;
  line-hegiht: 21px;

  height: 36px;
  padding: 0 12px 0 8px;
  border-radius: 8px;
  background-color: #E8ECF5;

  &:hover{
    opacity: .7;
  }
}

.detail-info-table-body-img{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  flex: 1 1 0;
  padding: 0 4px 0 16px;

  font-size: 15px;
  font-weight: 500;
  color: #000;
  line-height: 21px;

  img {
    width: 24px;
    object-fit: cover;
  }
}

.detail-info-table-body-img-between{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  flex: 1 1 0;

  font-size: 15px;
  font-weight: 500;
  color: #000;
  line-height: 21px;

  img {
    width: 24px;
    object-fit: cover;
  }
}

.grade-modal-select-item-wrap{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }
}

/* toast */

.toast-wrap {
  display: none;
  z-index: 300;

  flex-direction: row;
  align-items: center;
  justify-content: center;

  bottom: 50px;
  left: 0;
  position: fixed;
  width: 100%;
  padding: 0 16px;
  opacity: 0;
}

.toast-wrap.active {
  display: flex;
  animation-play-state: running;
  animation-name: toast;
  animation-duration: 5s;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

@keyframes toast {
  0% {
    opacity: 0;
    bottom: 0px;
  }10%{
    opacity: 1;
    bottom: 50px;
  }90% {
    opacity: 1;
    bottom: 50px;
  }100% {
    opacity: 0;
    bottom: 0px;
  }
}

.toast {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;

  min-width: 361px;
  height: 48px;
  background-color: #000;
  border-radius: 24px;

  color: #fff;
  font-size: 14px;
  font-weight: 500;

  img {
    width: 24px;
    height: 24px;
  }
}

.table-searchbar-row-between{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.add-consultant-btn{
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;

  color: #72FBA0;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;

  height: 44px;
  padding: 0 16px 0 12px;
  background-color: #20363B;
  border-radius: 8px;

  img {
    width: 28px;
    height: 28px;
  }
}

.add-consultant-modal-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;

  padding: 36px;
  border-radius: 8px;
  background-color: #fff;
  width: 650px;
}

.add-consultant-modal-input{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;

  width: 100%;

  .text-input{
    width: 100%;
  }
}

.add-consultant-label{
  font-size: 15px;
  font-weight: 500;
  color: #000;
  line-height: 21px;

  span{
    font-size: 15px;
    font-weight: 400;
    color: #5C5F6B;
    line-height: 21px;
  }
}

.add-consultant-modal-body{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  gap: 36px;
}

.add-consultant-modal-wrap{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  width: 100%;
}

.add-consultant-modal-garde-table{
  display: flex;
  flex-direction: column;
  align-items: stretch;

  width: 100%;
  border: .5px solid #DADDE5;
  border-radius: 12px;
}

.add-consultant-modal-grade-row{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  
  border-bottom: .5px solid #DADDE5;
}

.add-consultant-modal-grade-row.last{
  border-bottom: none;
}

.add-consultant-modal-grade-item{
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 0;
  gap: 8px;

  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }

  font-size: 15px;
  font-weight: 500;
  color: #3B3D47;
  line-height: 21px;

  height: 45px;
  padding: 0 16px;

  border-right: .5px solid #DADDE5;

  transition: .1s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover{
    background-color: #F7F9FC;
  }
}

.add-consultant-modal-grade-item.last{
  border-right: none;
}

/* consultant detail */

.consultant-detail-container{
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 100%;
}

.consultant-detail-table-head{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  height: 45px;

  font-size: 15px;
  font-weight: 500;
  color: #5C5F6B;
  line-height: 21px;
}

.consultant-detail-table-body{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  height: 53px;

  font-size: 15px;
  font-weight: 400;
  color: #1C1E24;
  line-height: 21px;
}

.consultant-detail-table-body.th-grade{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  img {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }
}

.consultant-detail-body-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  border-radius: 16px;
  background-color: #F2F4FA;
  padding: 24px;
}

.consultant-detail-body-title{
  font-size: 17px;
  font-weight: 700;
  color: #3B3D47;
  line-height: 25px;
}

.consultant-detail-body-date-wrap{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.consultant-detail-body-date{
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  padding: 0 13px 0 16px;
  height: 41px;
  border-radius: 12px;
  background-color: #E8ECF5;

  font-size: 15px;
  font-weight: 500;
  color: #000;
  line-height: 21px;

  img {
    width: 16px;
    height: 16px;
    object-fit: cover;
  }

  &:hover{
    opacity: .7;
  }
}

.consultant-detail-body-graph-wrap{
  display: flex;
  flex-direction: column;
  align-items: stretch;

  background-color: #fff;
  border-radius: 12px;
  width: 100%;
}

.consultant-detail-body-graph-head{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  padding: 24px 20px;
}

.consultant-detail-body-graph-head-title{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  img{
    width: 32px;
    height: 32px;
  }

  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  color: #000;
}

.consultant-detail-body-graph-head-amount{
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: #000;

  margin-left: 4px;
}

.consultant-detail-graph-wrap{
  width: 100%;
  height: 400px;
}

.consultant-detail-policy-sold-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;

  width: 100%;
  border-radius: 12px;
  background-color: #fff;
}

.consultant-policy-table-head{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  height: 45px;

  font-size: 15px;
  font-weight: 500;
  color: #5C5F6B;
  line-height: 21px;
}

.consultant-policy-table-body{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  height: 53px;

  font-size: 15px;
  font-weight: 400;
  color: #1C1E24;
  line-height: 21px;
}

.consultant-policy-table-head.th-date, .consultant-policy-table-body.th-date{
  flex: 1;
}

.consultant-policy-table-head.th-customer, .consultant-policy-table-body.th-customer{
  flex: 1;
}

.consultant-policy-table-head.th-profit, .consultant-policy-table-body.th-profit{
  flex: 2;
}

.consultant-policy-table-head.th-earn, .consultant-policy-table-body.th-earn{
  flex: 2;
}

.consultant-policy-table-head.th-sharing, .consultant-policy-table-body.th-sharing{
  flex: 1;
}

.consultant-policy-table-head.th-product, .consultant-policy-table-body.th-product{
  flex: 4;
}

.consultant-policy-table{
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: 0 24px 24px 24px;
  border-radius: 12px;
  background-color: #fff;
  width: 100%;
}

.consultant-policy-table-body-wrap{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-bottom: .5px solid #DADDE5;
}

.consultant-detail-head-wrap{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.calendar-modal-container{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: 20px;

  width: 420px;
  height: 493px;
  padding: 36px;
  border-radius: 8px;
  background-color: #fff;
}

/* react calendar */
.react-calendar{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  
  width: 100%;
}


.react-calendar__navigation{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.react-calendar__navigation__label{
  background: none;
  border: none;

  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: #000;

  font-family: 'Pretendard', Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next-button,
.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button{
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 28px;
  height: 28px;
  border-radius: 14px;

  background: none;
  outline: none;
  border: none;

  .img{
    width: 20px;
    height: 20px;
  }

  transition: .2s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover{
    background-color: #F2F4FA;
  }
}

.react-calendar__month-view__weekdays__weekday{
  display: flex;
  align-items: center;
  justify-content: center;

  abbr{
    text-decoration: none;

    font-family: 'Pretendard', Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;

    font-size: 13px;
    font-weight: 500;
    color: #000;
    line-height: 19px;
  }
}

[aria-label="Sunday"] {
  color: #FF243E !important;
}

[aria-label="Saturday"] {
  color: #2489FF !important;
}

.react-calendar__tile{
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 44px;
  height: 44px;
  border: none;

  background: none;

  abbr{
    font-family: 'Pretendard', Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;
    border-radius: 16px;

    font-size: 15px;
    font-weight: 700;
    line-height: 100%;
    color: #000;
  }

  transition: .2s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover{
    abbr{
      background-color: #F2F4FA;
    }
  }
}

.react-calendar__month-view > div > div{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
}

.react-calendar__tile.react-calendar__tile--now{
  abbr{
    background-color: #20363B;
    color: #72FBA0;
  }
}

.react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth{
  abbr{
    color: rgba(0, 0, 0, .3);
  }
}

.upload-btn-cancel.calendar-modal{
  flex: unset;
}

.upload-modal-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  input {
    margin-left: 6px;
  }
}

/* visit schedule */
.schedule-container {
  width: 100%;
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: row;
  gap: 24px;

  background-color: #F2F4FA;
  border-radius: 16px;

  padding: 24px;
}

.schedule-container{
  .react-calendar{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 48px;
    
    flex: 1;
    padding-top: 24px;
  }
  
  .react-calendar__navigation{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button{
    visibility: hidden;
    display: none;
  }
  
  .react-calendar__navigation__label{
    background: none;
    border: none;
  
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    color: #000;

    width: fit-content;
    /* flex-grow: unset !important; */
  
    font-family: 'Pretendard', Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
  
  .react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
  .react-calendar__navigation__arrow.react-calendar__navigation__next-button,
  .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
  .react-calendar__navigation__arrow.react-calendar__navigation__next2-button{
    cursor: pointer;
  
    display: flex;
    align-items: center;
    justify-content: center;
  
    width: 36px;
    height: 36px;
    border-radius: 18px;
  
    background: none;
    outline: none;
    border: none;
  
    .img{
      width: 32px;
      height: 32px;
    }
  
    transition: .2s cubic-bezier(0.075, 0.82, 0.165, 1);
  
    &:hover{
      background-color: #E3E7EF;
    }
  }
  
  .react-calendar__month-view__weekdays__weekday{
    display: flex;
    align-items: center;
    justify-content: center;
  
    abbr{
      text-decoration: none;
  
      font-family: 'Pretendard', Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
  
      font-size: 17px;
      font-weight: 500;
      color: #000;
      line-height: 25px;
    }
  }
  
  [aria-label="Sunday"] {
    color: #FF243E !important;
  }
  
  [aria-label="Saturday"] {
    color: #2489FF !important;
  }
  
  .react-calendar__tile{
    position: relative;
    cursor: pointer;
  
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  
    width: 100%;
    height: 116px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: inset 0 0 0 2px #F2F4FA;
    border: none;

    padding: 16px;
  
    abbr{
      font-family: 'Pretendard', Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      width: fit-content;
      height: fit-content;
      border-radius: 16px;
  
      font-size: 15px;
      font-weight: 700;
      line-height: 100%;
      color: #000;
    }
  
    transition: .2s cubic-bezier(0.075, 0.82, 0.165, 1);
  
    &:hover{
      background-color: #F1FFF7;
    }
    abbr{
      background: none;
    }
  }

  .react-calendar__tile.selected{
    background-color: #E2FFF0;
  }
  
  .react-calendar__month-view > div > div{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
  }
  
  .react-calendar__tile.react-calendar__tile--now{
    abbr{
      background: none;
      color: #00B55A;
    }
  }
  
  .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth{
    abbr{
      color: rgba(0, 0, 0, .3);
    }
  }
  
}

.schedule-detail-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;

  width: 649px;
  background-color: #fff;
  border-radius: 12px;

  padding: 28px 24px;
}

.schedule-amount{
  font-size: 15px;
  font-weight: 700;
  color: #fff;
  line-height: 21px;

  padding: 2px 8px;
  border-radius: 13px;
  background-color: #2489FF;
}

.schedule-canReserve{
  position: absolute;

  top: 2px;
  left: 2px;

  width: 20px;
  height: 20px;

  border-radius: 8px 0 0 0;
}

.schedule-visit-btn{
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  padding: 4px 12px 4px 8px;
  border-radius: 4px;
  border: 1px solid #E4E6ED;
  width: fit-content;

  font-size: 15px;
  font-weight: 500;
  color: #1C1E24;

  img{
    width: 29px;
    height: 29px;
  }

  transition: .2s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover{
    border: 1px solid #D3D6E0;
  }
}

.schedule-visit-top{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.schedule-visit-date{
  font-size: 20px;
  font-weight: 700;
  color: #000;
  line-height: 28px;
}

.visitor-table-head-row{
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 15px;
  font-weight: 500;
  color: #5C5F6B;
  line-height: 21px;
}

.visitor-table-body-row{
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 15px;
  font-weight: 400;
  color: #1C1E24;
  line-height: 21px;

  img{
    width: 23px;
    height: 15px;
  }
}

.visitor-table-head-row.col1, .visitor-table-body-row.col1{
  flex-grow: 1;
}

.visitor-table-head-row.col2, .visitor-table-body-row.col2{
  width: 144px;
}

.visitor-table-head-row.col3, .visitor-table-body-row.col3{
  flex-grow: 1;
}

.visitor-table-head-row.col4, .visitor-table-body-row.col4{
  width: 144px;
}

.schedule-visit-table-wrap{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  width: 100%;
}

.schedule-visit-title{
  font-size: 17px;
  font-weight: 700;
  color: #000;
  line-height: 25px;
}

.table-col.visitor{
  width: 100%;
}

.table-item.visitor{
  border-bottom: 1px solid #EBECF2;

  &:hover{
    background-color: #F9F9FD;
  }
}

.schedule-visit-textarea{
  resize: none;
  outline: none;

  padding: 12px 16px;
  width: 100%;
  min-height: 171px;
  border: 1px solid #DADDE5;
  background-color: #FAFAFC;
  border-radius: 8px;

  font-size: 17px;
  font-weight: 500;
  color: #000;
  line-height: 25px;

  &::placeholder{
    color: #7F828F;
  }

  font-family: 'Pretendard', Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}


/* task info table */


.invoice-info-table-head{
  display: flex;
  align-items: center;
  justify-content: center;

  height: 45px;

  font-size: 15px;
  font-weight: 500;
  color: #5C5F6B;
  line-height: 21px;
}

.invoice-info-table-body{
  display: flex;
  align-items: center;
  justify-content: center;

  height: 53px;

  font-size: 15px;
  font-weight: 400;
  color: #1C1E24;
  line-height: 21px;
}

.invoice-info-table-head.th-type, .invoice-info-table-body.th-type{
  flex: 2;
}

.invoice-info-table-head.th-premium, .invoice-info-table-body.th-premium{
  flex: 2;
}

.invoice-info-table-head.th-levy, .invoice-info-table-body.th-levy{
  flex: 1;
}

.invoice-info-table-head.th-transfer, .invoice-info-table-body.th-transfer{
  flex: 1;
}

.invoice-info-table-head.th-date, .invoice-info-table-body.th-date{
  flex: 1;
}

.invoice-info-table-head.th-file, .invoice-info-table-body.th-file{
  flex: 2;
}

.invoice-info-table-body-download{
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  img {
    width: 24px;
    height: 24px;
  }

  font-size: 15px;
  font-weight: 500;
  color: #000;
  line-height: 21px;

  &:hover{
    text-decoration: underline;
  }
}

.detail-info-title.between{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

/* input */

.upload-modal-input-wrap{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;

  width: 100%;
}

.upload-modal-input-label{
  font-size: 13px;
  font-weight: 500;
  color: #222;
}

.input-list-wrap{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  width: 100%;
}

.text-input.fill{
  width: 100%;
}