@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Pretendard', Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E6EAF5;

  overflow: auto;
}

body::-webkit-scrollbar{
  width: 10px;
}

body::-webkit-scrollbar-track{
  color: #E6EAF5;
  width: 10px;
}

body::-webkit-scrollbar-thumb{
  background-color: #A9B2CC;
  border-radius: 4px;
  box-shadow: inset 0 0 0 2px #E6EAF5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  outline: none;
}

a {
  text-decoration: none;
}